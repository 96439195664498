@import "../scss/general_styling";

.ProtocolSection {
  display: flex;
  padding-top: 2em;

  .Left {
    width: 50%;

    h1 {
      @include primary-text($title, $white, 400);
      line-height: $title;
    }
    .DescriptionContainer {
      width: 80%;
      padding: 2em 0;
      p {
        @include primary-text($h4, #a0a0ac, 400);
        margin-bottom: 1em;
      }
    }
  }

  .Right {
    width: 50%;
    padding: 3em 0 0 5em;
    .SubtTitle {
      @include primary-text($h2, $white, 400);
    }
    .CoinsContainer {
      border-top: 1px solid $grey-5;
      padding: 2em 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .CoinCard {
        width: 48%;
      }
      .ReservesCard {
        width: 100%;
        margin-top: 2em;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .ProtocolSection {
    .Left {
      h1 {
        @include primary-text($subtitle, $white, 400);
        line-height: $subtitle;
      }
      .DescriptionContainer {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .ProtocolSection {
    flex-direction: column;
    .Left {
      width: 100%;
    }
    .Right {
      width: 100%;
      padding: 3em 0 0 0;
    }
  }
}

@media only screen and (max-width: 425px) {
  .ProtocolSection {
    padding-top: 0;
    .Left {
      h1 {
        @include primary-text($title-mobile, $white, 400);
        line-height: $title-mobile;
      }
    }
    .Right {
      .CoinsContainer {
        flex-direction: column;
        .CoinCard {
          width: 100%;
          &:first-child {
            margin-bottom: 2em;
          }
        }
        .ReservesCard {
          .Content {
            flex-direction: column;
            .BaseReserves,
            .ReservesRatio {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
