@import "../../../scss/general_styling";

.ReservesCard {
  background: #100901;
  border-radius: 8px;
  border: 1px solid $grey-5;
  padding: 1em;
  hr {
    border: none;
    border-top: 1px solid $grey-5;
  }

  .Content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    .BaseReserves {
      span {
        @include primary-text($label, $white, 400);
        margin: 0;
      }
      h3 {
        @include number-text($numbers, $white, 600);
        line-height: 1;
        margin-bottom: 0.25em;
      }
      p {
        @include number-text($h4, $white, 400);
      }
    }
    .ReservesRatio {
      display: flex;
      flex: 1;
      .NumberItem {
        margin-right: 2em;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .ReservesCard {
    .Content {
      gap: 10px;
      .BaseReserves {
        h3 {
          @include number-text($h3, $white, 600);
          line-height: 1;
          margin-bottom: 0.75em;
        }
        p {
          @include number-text($body, $white, 600);
          margin-bottom: 0.75em;
        }
      }
      .ReservesRatio {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
