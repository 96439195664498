@import "../../../scss/general_styling";

.custom-connect-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    margin: 0 1em;
  }
}

.wrapper-connect-btn {
  position: relative;

  & > button {
    @include buttonBase(0.5em 1.25em, 8px);
    @include primary-text($body, $white, 600);
    background: $black-2;
    outline: none;
    cursor: pointer;

    border: 2px solid $black-3;
    height: 50px;
    &:hover,
    &:focus {
      background: $black-2;
      border-color: inherit;
      color: #fff;
    }
  }
}
