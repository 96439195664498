@import "../scss/general_styling";

main {
  padding: "1rem 0";
}
.TermsSection {
  padding-top: 2em;
  h1 {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    font-weight: 400;
    color: #ffffff;
    line-height: 50px;
  }
  h3 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
  }
  .Content {
    padding: 2em 0;
    p,
    ul li {
      margin: 0;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      font-weight: 400;
      color: #a0a0ac;
      margin-bottom: 1em;
    }
  }
}
