@import "../scss/general_styling";

.MyBalanceSection {
  display: flex;
  flex-direction: column;
  padding-top: 2em;

  .Top {
    width: 100%;
    h1 {
      @include primary-text($h1, $white, 700);
      margin-bottom: 0;
    }
    p {
      @include primary-text($h4, #a0a0ac, 400);
      margin-bottom: 1em;
    }
    .Content {
      display: flex;
      justify-content: space-between;
    }
  }

  .Bottom {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .ProtocolSection {
    flex-direction: column;
    .Left {
      width: 100%;
    }
    .Right {
      width: 100%;
      padding: 3em 0 0 0;
    }
  }
}

@media only screen and (max-width: 425px) {
  .MyBalanceSection {
    .Top {
      .Content {
        flex-direction: column;
      }
    }
  }
}
