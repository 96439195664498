$primary-color: #4962fc;
$secondary-color: #6e5cdc;
$warning-color: #fec004;

$black-1: #262932;
$black-2: #100901;
$black-3: #41455c;
$white: #ffffff;

$grey-1: #f4f4f6;
$grey-2: #e9e9ee;
$grey-3: #b5b0b0;
$grey-4: #bbbbbb;
$grey-5: #5f606e;

$gold-dark: #d07d2c;
$gold-light: #f49422;

a,
a:focus,
a:active {
  color: #d07d2c;
}

a:hover {
  color: #f49422;
}

$gradient-theme-1: linear-gradient(212.41deg, #12173a 1.12%, #0c0c0c 94.46%);
$gradient-theme-2: linear-gradient(70.09deg, #00e29a 0%, rgba(0, 226, 154, 0) 100%);
$gradient-theme-3: linear-gradient(
  91.41deg,
  rgba(255, 255, 255, 0.3) 0%,
  rgba(255, 255, 255, 0) 100%
);
$gradient-theme-4: linear-gradient(70.09deg, #212121 0%, #1a192e 100%);
$gradient-theme-6: linear-gradient(250.41deg, #98510a 1.12%, #000000 60.46%);
$gradient-theme-5: linear-gradient(320.41deg, #fba856 0.12%, #000000 60.46%);

$shadow: 0px 4px 4px 0px #00000040;

$title: 75px;
$subtitle: 50px;
$numbers: 22px;
$title-mobile: 40px;
$h1: 30px;
$h2: 20px;
$h3: 18px;
$h4: 16px;
$button: 14px;
$body: 14px;
$label: 12px;
$explain: 10px;

@mixin primary-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}

@mixin number-text($font-size, $font-color, $font-weight) {
  margin: 0;
  font-family: "Roboto Mono", monospace;
  font-size: $font-size;
  font-weight: $font-weight;
  @if $font-color {
    color: $font-color;
  }
}

/* Buttons */

@mixin buttonBase($padding, $borderRad) {
  padding: $padding;
  border-radius: $borderRad;
  cursor: pointer;
}

@mixin ButtonPrimary($color, $background) {
  color: $color;
  background-color: $background;
  cursor: pointer;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
